//global app namespace
var bourboneat_app = bourboneat_app || {};

//define global app vars here. e.g.,
/*
    bourboneat_app['site_base']='//mydomain.net/';
*/
bourboneat_app.header = {

    init: function($) {

        var menuToggle = $('#menu-main-mobile').unbind();
        var menuMain = $('#menu-main');
        var sidebar = $('#secondary');

        menuMain.removeClass("show");
        sidebar.removeClass("show");

        menuToggle.on('click', function(e) {
            e.preventDefault();

            menuMain.slideToggle(function() {
                if(menuMain.is(':hidden')) {
                    menuMain.removeAttr('style');
                }
            });

            sidebar.slideToggle(function() {
                if(sidebar.is(':hidden')) {
                    sidebar.removeAttr('style');
                }
            });

        });

    }
};
/*
    call this IIFE which has jQuery mapped to $
    last after all modules are defined.
    pass $ for functions that depemd on jQuery
*/
(function ($) {

    //modules/header.js
    bourboneat_app.header.init($);

})( jQuery );